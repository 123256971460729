<template>
  <div class="m-24">
    <h1 class="mb-24 font-semibold text-2lg uppercase">Playground</h1>

    <h3 class="mb-24 font-semibold text-lg uppercase">Texts:</h3>

    <div class="mb-36">
      <h2 class="font-extrabold text-xl mb-16">Sign in to your account</h2>

      <p class="text-lg font-medium leading-big mb-16">Order: 908275092752</p>

      <p class="text-lg mb-16">kr. 1680,00</p>

      <p class="mb-16">Johan Staureby</p>

      <p class="font-medium text-grey-400 mb-16">Customer</p>

      <p class="text-grey-300">Adding track n trace number will trigger a notification to the customer</p>
    </div>

    <h3 class="mb-24 font-semibold text-lg uppercase">Buttons:</h3>

    <div class="flex flex-wrap mb-12">
      <el-button tag="button" class="mr-16 mb-24">Default button</el-button>

      <el-button tag="button" type="primary" class="mr-16 mb-24">Green button</el-button>

      <el-button
        tag="button"
        type="primary-dark"
        class="group mr-16 mb-24"
        style="width: 500px;"
      >
        <template #icon>
          <span class="icon-lock text-green text-2lg block group-hover:text-green-300" />
        </template>
        Green button
      </el-button>

      <el-button
        tag="button"
        type="primary-dark"
        loading
        class="group mr-16 mb-24"
        style="width: 500px;"
      >
        <template #icon>
          <span class="icon-lock text-green text-2lg block group-hover:text-green-300" />
        </template>
        Green button
      </el-button>

      <el-button tag="button" type="primary" :disabled="true" class="mr-16 mb-24">Disabled button</el-button>
    </div>

    <div class="flex mb-36">
      <el-button tag="a" type="link" class="mr-16">Link button</el-button>

      <el-button tag="a" type="action" class="mr-16">Action button</el-button>

      <el-button tag="a" type="action" disabled class="mr-16">Disabled</el-button>
    </div>

    <h3 class="mb-24 font-semibold text-lg uppercase">Text fields:</h3>

    <div class="flex mb-36">
      <el-input v-model="email" placeholder="Email address" input-class="rounded-t" class="mr-16" />

      <el-input v-model="email" placeholder="Email address" input-class="rounded-b" class="mr-16" />

      <el-input rounded-bottom placeholder="Email address" input-class="rounded" class="mr-16" />

      <el-input v-model="password" placeholder="Password" type="password" rounded class="mr-16" />

      <el-input v-model="disabledInput" placeholder="Disabled input" :disabled="true" rounded class="mr-16" />
    </div>

    <h3 class="mb-24 font-semibold text-lg uppercase">Checkboxes, Radio buttons:</h3>

    <div class="flex">
      <el-checkbox v-model="isRemember" class="mr-16"><span>Remember me</span></el-checkbox>

      <el-checkbox v-model="isRemember" disabled><span>Disabled</span></el-checkbox>
    </div>
  </div>
</template>

<script>
const ElButton = () => import('@/components/shared/ELButton')
const ElInput = () => import('@/components/shared/ELInput')
const ElCheckbox = () => import('@/components/shared/ELCheckbox')

export default {
  name: 'Playground',

  components: { ElButton, ElInput, ElCheckbox },

  data () {
    return {
      email: 'test@gmail.com',
      password: '',
      disabledInput: '',
      isRemember: false
    }
  }
}
</script>
